import { BhpMarketTypes } from "../pages/BussinessHomePage";

const BACKEND_SERVER_LOCAL = "http://localhost:8443";
const IDIGIZEN_FRONTEND_URL = "https://vypzee.com/";
//const IDIGIZEN_FRONTEND_URL = "http://test.myapp.localhost:8100";
const IDIGIZEN_BACKEND_URL = "https://api.newprod.idigizen.com";
// const IDIGIZEN_BACKEND_URL = "http://localhost:8443";
const domain = ".idigizen.com";
// const domain = ".myapp.localhost";
//const IDIGIZEN_BACKEND_URL = "http://localhost:8443";
// const IDIGIZEN_BACKEND_URL = "http://10.5.50.125:8443";
// const IDIGIZEN_BACKEND_URL = "http://192.168.1.214:8443";
const API_SECURE_KEY =
  "8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2";
//const backend_cert_server = "https://api.newprod.idigizen.com";
// const backend_cert_server = "http://localhost:8443";
// const backend_admin_route = "https://api.admin.idigizen.com";
// const backendprod_route = "https://api.idigizen.com";
const sqlPullStudentList =
  "select u.id as userId, u.firstname as studentName, u.standard, u.section, count(d.id) as numAccolades, u.phoneNumber from userdata as u inner join documents as d on d.userId = u.id where u.schoolId=25 group by u.id order by numAccolades desc";
// const sqlPullStudentAccolades =
//   "select concat (u.firstname, ' ', u.lastName) as studentName, concat (u.standard, ' ', u.section) as standard, d.docTitle, d.type, d.what, d.WHERE, d.WHEN, d.docDescription, d.fileName from userdata as u inner join documents as d on d.userId = u.id where u.id=6050";
const sqlPullStudentAccolades =
  "select concat (u.firstname, ' ', u.lastName) as studentName, concat (u.standard, ' ', u.section) as standard, d.docTitle, d.type, d.what, d.WHERE, d.WHEN, d.docDescription, d.fileName from userdata as u inner join documents as d on d.userId = u.id where u.id=%%ID%%";
//const backend_cert_server = "http://localhost:3033";
const userInfoRouter = "get-user-info";
const getBehaviorTraitsRouter = "get-behavior-class-sec-sess-exam-bundle";
const subClassSecSessExamBundleRouter = "get-sub-class-sec-sess-exam-bundle";
const subClassSecSessExamMarksSaveRouter =
  "save-sub-class-sec-sess-exam-bundle";
const subClassSecSessTraitsBundleSaveRouter =
  "save-sub-class-sec-sess-traits-bundle";
const subClassSecSessStudentTraitsBundleSaveRouter =
  "save-sub-class-sec-sess-student-traits-bundle";
const getBehaviorTraitsPerStudentRouter =
  "get-behavior-class-sec-sess-exam-student-bundle";
const getStudentListSessionClassSection = "get-class-sec-sess-student-list";
const getReportCardStudentListClassSection = "get-student-list-for-report-card";
const getSessPerStudentReport = "get-per-student-report";
const saveStudentAttendance = "student-attendance-save";
const getStudentReportCardComments = "get-student-report-card-comments-db";
const getReportCardComments = "get-report-card-comments-db";
const saveReportCardComments = "save-student-report-card-comments-db";
const deleteReportCardComments = "delete-student-report-card-comments-db";
const studentIProfileMarksSave = "student-iprofile-marks-save";
const saveAnecdote = "save-student-anecdote-db";
const deleteAnecdote = "del-student-anecdote-db";
const getStudentAnecdoteList = "get-student-anecdote-db";
const getAnecdoteList = "get-anecdote-db";
const getEventListDB = "get-event-list";
const getEventInputDB = "get-event-input-db";
const getEventDetailsDB = "get-event-details";
const getEventDetailsForBusinessPage = "get-event-details-for-business-page";
const getFileThumbnailDB = "getThumbnail";
const updateEventStatusDB = "update-event-status";
const getStudentAttendanceListSessionClassSection =
  "get-class-sec-sess-student-attendance-list";

const getCertTemplateNames = "get-template-names";
const getCertFontNames = "get-font-names";
const getCertTemplateThumbnailFolder = "thumbnail";
const getCertFontFolder = "font-images";
const saveEventDB = "save-event";
const getEventParticipantsDB = "get-event-participants";
const updateEventParticipantsDB = "update-event-participants";
const generateCertificatesCommand = "generate-certificates";
const reviewCertificatesPath = "cert-thumbnail";
const deleteEventDB = "delete-event";
const addParticipantToDB = "add-event-participants";
const logoutRoute = "logout";
const DOMAIN_PATH = ";domain=.idigizen.com";
const getLandingPageSpecs = "get-landing-page-specs";
const getLandingPageIconImg = "landing-page-icon-img";
const saveFormDB = "save-form-db";
const getFormDB = "get-form-db";
const getFormsListDB = "get-forms-list-db";
const saveFormInstance = "save-form-instance";
const getFormInstanceList = "get-form-instances";
const getFormInstanceDetails = "get-form-instance-details";
const saveTableDB = "save-table-db";
const deleteTableDB = "delete-table-db";
const deleteTableEntryDB = "delete-table-entry-db";
const getTableDB = "get-table-db";
const saveTableColSizeDB = "save-table-col-size-db";
const getTablesListDB = "get-tables-list-db";
const getTableDataDB = "get-table-data-db";
const getDashboardDB = "get-dashboard-data";
const saveBlob = "save-blob";
const getDynamicColValuesDB = "get-dynamic-col-values";
const getDynamicColValuesDBForForm = "get-dynamic-col-values-for-form";
const saveTableDataBackend = "save-table-data";
const initTimeTable = "init-time-table";
const getTimeTable = "get-time-table";
const saveTimeTable = "save-time-table";
const getTimeTableClassList = "get-time-table-class-list";
const generateReportCards = "generate-report-cards";
const getTermListDB = "get-term-list";
const getReportCardReview = "get-report-card-review";
const getReportCardThumbnail = "get-report-card-thumbnail";
const deleteEventParticiapntDB = "delete-event-participant";
const updateUserInfo = "update-user-info";
const deleteElementFromMapsDB = "del-element-from-map";
const generateTimeTable = "generate-time-table";
const savePTMLog = "save-ptm-log";
const getStudentPTMLogs = "get-student-ptm-logs";
const saveHomeWork = "save-home-work";
const getHomeWorkList = "get-home-work-list";
const reportVerticals = ["Scholastic", "Co-Scholastic", "Behavior"];
const uploadFiles = "upload-files";
const careerPredictorApi = "idigizen-ai-backend";
const getCompletion = "get-completion";
const updateAccolade = "update-record";
const addSsiEcaScoreDB = "add-ssi-eca-score-db";
const getSsiCountDB = "get-ssi-count-db";
const getTableMobStructDB = "get-table-mob-db";
const saveTableMobStructDB = "save-table-mob-db";
const getEcaActivitiesDB = "get-eca-activities-db";
const getSsiListDB = "get-ssi-list-db";
const getSsiEcaScoreDB = "get-ssi-eca-score-db";
const addCheckUserDB = "add-check-user-db";
const registerZPreneurStudent = "register-zpreneur-student";
const zPreneurCourseDetails = "zPreneur-course-details";
const getCountryStateCityNGstData = "get-country-state-city-gst-data";
const updateUserDataDB = "update-user-info";
const updateSchoolDB = "update-school-info";
const checkAndSaveDomainName = "check-and-save-domain-name";
const getBhpCompanyDetailsDB = "get-bhp-company-details-db";
const getBhpTeamDetailsDB = "get-bhp-team-details-db";
const getBhpProductDetailsDB = "get-bhp-product-details-db-categorized";
const getBhpPromoProductDetailsDB = "get-bhp-promo-product-details-db";
const getBhpTestimonialsDetailsDB = "get-bhp-testimonials-db";
const saveBhpTestimonialsDetailsDB = "save-bhp-testimonials-db";
const queryBhpTestimonialsDetailsDB = "query-bhp-testimonials-db";
const saveBhpCustomerQueryDB = "save-bhp-customer-query";
const placeZPreneurOrder = "zPreneur-order";
const getResumeHtmlDB = "get-resume-html";
const getResumeCssDB = "get-resume-css";
const getResumeDataDB = "get-data-for-resume";
const generateResumeDB = "generate-resume";
const googleSignIn = "google-sign-in";
const vCardGetDB = "get-vcard-db";
const getOrderDetailsDB = "get-order-details";
const getQueryDetailsDB = "get-query-details";
const getItemizedOrderDetailsDB = "get-order-itemized-details";
const updateOrderStatusDB = "update-order-status";
const getOrderStatusDB = "get-order-status";
const getFavShopMarketListDB = "get-fav-market-shop";
const writeFavShopMarketListDB = "write-fav-market-shop";
const getBhpMarketsDB = "get-bhp-markets-db-categorized";
const certiDownloadURL = "download-all-certs-for-event";
const validateCoupon = "validate-coupon";
const saveMerchantPromoDb = "save-merchant-promo";
const getLoyaltyProgramRulesDB = "get-loyalty-program-rules";
const getLoyaltySnapshotPerUserOrShopDB =
  "get-loyalty-snapshot-per-user-or-shop";
const setLoyaltyProgramRulesDB = "set-loyalty-program-rules";
const addRedeemLoyaltyPointsDB = "add-redeem-loyalty-points";
const getLoyaltyDetailsPerUserAndShopDB =
  "get-loyalty-details-per-user-and-shop";
const registerNewLoyaltyCustomerDB = "register-new-loyalty-customer";
const getCustomerShopLoyaltySnapshot = "get-customer-shop-loyalty-snapshot";
const getTinyUrlDetailsDB = "get-check-for-tiny-url";
const getCouponsDB = "fetch-coupons";
const getBhpHotKeysDB = "get-bhp-hot-keys";
const downloadCouponForPromotion = "download-coupon-for-promotion";
const validateCouponDB = "validate-coupon";
const redeemCouponDB = "redeem-coupon";
const getMarketFloorsDB = "get-all-floors";
const getMarketFloorLayoutDB = "get-floor-layout-elements";
const saveMarketFloorLayoutDB = "save-floor-layout-elements";
const getSearchResults = "get-search-results";
const getPreEmptiveSearchResults = "get-preemptive-search-list";
const getSetResetPasswordDB = "get-set-reset-password-db";
const tableBookingDB = "table-booking-db";
const getTableBookingDB = "get-table-booking-db";
const savePloygonLineClusterDB = "save-floor-layout-linecluster";
const updateTableBookingDB = "update-table-booking-db";
const getCustomerDB = "get-customer-db";
const recordToSResponse = "record-tos-response";
const getShopStockPhotosDB = "get-shop-stock-photos";
const saveShopStockPhotosDB = "save-shop-stock-photos";
const getUnapprovedLoyaltyEntryDB = "get-unapproved-loyalty-entry-list";
const saveStatusUnapprovedLoyaltyEntryDB =
  "save-status-unapproved-loyalty-entry";
const getMetaTags = "get-meta-tags";
const subscribeToOfferDB = "subscribe-to-offer";
const saveMapsAdditionalStyleDB = "save-map-additional-styles";
const getShopMapMarkerAssocDB = "get-shop-map-marker-association";
const updateShopAssignmentDB = "update-shop-assignment";
const getCompanyDomainFromParamsDB = "get-bhp-company-domain-from-params";
const getBlogsListDB = "get-blogs-list-db";
const getBlogDB = "get-blog-db";
const getBlogHtmlFile = "get-blog-html-file-db";
const saveUserInteractionDB = "save-user-interaction-db";
const getPremptMktShopNameDB = "get-prempt-mkt-shop-name-db";
const saveReceiptDB = "save-receipt-db";
const loginUserReceiptDB = "login-for-receipt-db";
const getReceiptsDB = "get-receipt-db";
const sendMessageToCustomer = "sendMessageToCustomer";
const associateShopWithUser = "associate-shop-with-user";
const getAllMarketBlocksDB = "get-all-market-blocks-db";
const assignBhpStockImagesDB = "assign-random-stock-photos";
const registerShoppingListDB = "shopping-list-register-member";
const createEditShoppingListDB = "create-update-shopping-list";
const getShoppingListDB = "get-shopping-list";
const getShopChecklistDataDB = "get-shop-checklist-data";
const saveShopChecklistDataDB = "save-shop-checklist-data";
const downloadRawQrForShop = "download-raw-qr-code-for-shop";
const sendQueryForProductInfo = "send-query-for-product-info";
const replyToCustomerQueryDB = "reply-query-details";
const getAllCustomerQueriesDB = "get-all-query-details";
const generateOtpDB = "generate-login-otp";
const regenerateOtpDB = "regenerate-login-otp";
const verifyOtpDB = "verify-login-otp";
const recordDiscountDB = "record-discount";
const getDiscountDB = "get-discount";

const encryptionKey = "2b7e151628aed2a6abf7158809cf4f3c739ns9";
const userUIDKey = "userIdentifier";
const userSessionKey = "userSessionIdentifier";
const userSessionTimeKey = "userSessionTime";

const knownDomains: { domain: string; subdomain: string }[] = [
  { domain: "idigizen", subdomain: "idigicator" },
  { domain: "vypzee", subdomain: "market" },
  { domain: "myapp", subdomain: "test" },
];

const isKnownDomain = (domain: string): boolean => {
  // This is a special case for vypzee.com where we need the path to go to 'home-page'
  if (domain === "vypzee.com") return false;
  const subDomian = domain.split(".");
  let unknown = false;
  const domainL = knownDomains.find((el) => el.domain === subDomian[1]);
  if (!domainL) return true;
  if (
    subDomian.length >= 2 &&
    subDomian[1] === domainL.domain &&
    subDomian[0] !== domainL.subdomain
  ) {
    unknown = true;
    console.log("Set domain and change path ", subDomian[0]);
    // dispatch(businessNameInfoActions.updateName({ name: subDomian[0] }));
  } else {
    unknown = false;
    //console.log("Not change path ", subDomian);
  }
  return !unknown;
};

const vypzeeSupportNum = "8448338565";
var rupeeSymbol = "\u20B9";

const whatsappMessage = (msg: string, phone: string = ""): boolean => {
  let whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    msg
  )}`;
  if (!!phone) {
    let phoneNumber = phone.trim();
    phoneNumber =
      phoneNumber.charAt(0) === "+" ? phoneNumber.slice(1) : phoneNumber;
    let frags = phoneNumber.split(" ");
    let length = 0;
    for (const frag of frags) length += frags.length;
    let finalPhone = "";
    let stdCode = "91";
    if (length === 10) {
      finalPhone = phoneNumber;
    } else {
      const phoneRestruct = frags.join("");
      if (phoneRestruct.length === 12) {
        stdCode = phoneRestruct.slice(0, 2);
        finalPhone = phoneRestruct.slice(2);
      } else if (phoneRestruct.length === 10) {
        finalPhone = phoneRestruct;
      } else {
        console.log(
          "Error the phone is ",
          phoneRestruct,
          " input phone ",
          phone
        );
        return false;
      }
    }
    whatsappUrl += `&phone=${stdCode}${finalPhone}`;
  }

  console.log("Send whatsapp url ", whatsappUrl);
  // window.open(whatsappUrl, "_system");
  window.location.href = whatsappUrl;
  return true;
};

const contactSupport = (msg: string) => {
  // const whatsappUrl = `https://api.whatsapp.com/send?phone=91${vypzeeSupportNum}&text=${encodeURIComponent(
  //   msg
  // )}`;
  // window.open(whatsappUrl, "_system");
  whatsappMessage(msg, vypzeeSupportNum);
};

interface UserInteractionRecordType {
  pageType: "RWA" | "MARKET" | "USER" | "SHOP" | "VYPZEE";
  pageId: number | string;
  pageSection:
    | "MAIN"
    | "AD_POP"
    | "CATEGORY_EXPAND_MAIN"
    | "CATEGORY_EXPAND_SEARCH"
    | "TREND_POP"
    | "SEARCH"
    | "AD_ALL"
    | "FOOTER"
    | "NAVIGATION"
    | "TREND_ALL";
  popType?: "AD" | "TREND" | "SHOP" | "SEARCHED_SHOP" | "PROD" | "QUERY";
  popId?: number | string;
  action: string;
  notes?: string;
  url?: string;
  userAgent?: string;
}

const getPageType = (
  isMarket: boolean,
  marketType: BhpMarketTypes = undefined
): "RWA" | "MARKET" | "USER" | "SHOP" | "VYPZEE" => {
  if (!isMarket) return "SHOP";
  else {
    if (marketType === "MARKET") return "MARKET";
    if (marketType === "RWA") return "RWA";
    if (marketType === "USER") return "USER";
    if (marketType === "VYPZEE") return "VYPZEE";
  }
};

const bhpEditLinks = {
  shopProfile:
    "/landing-page?type=Table&id=329&storeId=%storeId%&singleCellEdit=1&backOnSave=1",
  addCategory:
    "/landing-page?type=Table&id=330&schoolId=%storeId%&singleCellEdit=1&addModeOnly=1&catName=0&backOnSave=1",
  editCategory:
    "/landing-page?type=Table&id=330&schoolId=%storeId%&singleCellEdit=1&catName=%catName%&backOnSave=1",
  addProduct:
    '/landing-page?type=Table&id=331&singleCellEdit=1&addModeOnly=1&prodId=0&backOnSave=1&schoolId=%SCHOOL_ID%&defaultValues={"categoryId":667}',
  addShop:
    "/landing-page?type=Table&id=378&singleCellEdit=1&addModeOnly=1&prodId=0&backOnSave=1&schoolId=%SCHOOL_ID%",
  editProduct:
    "/landing-page?type=Table&id=331&singleCellEdit=1&prodId=%prodId%&backOnSave=1&schoolId=%SCHOOL_ID%",
  editShop:
    "/landing-page?type=Table&id=378&singleCellEdit=1&backOnSave=1&schoolId=%SCHOOL_ID%",
  addOffer:
    "/landing-page?type=Table&id=332&singleCellEdit=1&addModeOnly=1&offerId=0&backOnSave=1&schoolId=%SCHOOL_ID%",
  editOffer:
    "/landing-page?type=Table&id=332&singleCellEdit=1&offerId=%offerId%&backOnSave=1&schoolId=%SCHOOL_ID%",
  addMarketOffer:
    "/landing-page?type=Table&id=417&singleCellEdit=1&addModeOnly=1&offerId=0&backOnSave=1&schoolId=%SCHOOL_ID%",
  editMarketOffer:
    "/landing-page?type=Table&id=417&singleCellEdit=1&offerId=%offerId%&backOnSave=1&schoolId=%SCHOOL_ID%",
  addTrend:
    "/landing-page?type=Table&id=333&singleCellEdit=1&addModeOnly=1&offerId=0&backOnSave=1&schoolId=%SCHOOL_ID%",
  editTrend:
    "/landing-page?type=Table&id=333&singleCellEdit=1&offerId=%offerId%&backOnSave=1&schoolId=%SCHOOL_ID%",
  checklistPhase2:
    "/landing-page?type=Table&id=359&singleCellEdit=1&backOnSave=1&schoolId=%SCHOOL_ID%",
  reviewShopMarket:
    "/landing-page?type=Table&id=328&schoolId=%SCHOOL_ID%&schoolType=%SCHOOL_TYPE%",
  reviewShopsInMarket: "/landing-page?type=Table&id=409&schoolId=%SCHOOL_ID%",
};

//override specific links for BNI Chapter
const bhpEditLinksBNIChapter = {
  ...bhpEditLinks,
  ...{
    shopProfile:
      "/landing-page?type=Table&id=342&storeId=%storeId%&singleCellEdit=1&backOnSave=1",
    addProduct:
      "/landing-page?type=Table&id=335&singleCellEdit=1&addModeOnly=1&prodId=0&backOnSave=1&schoolId=%SCHOOL_ID%",
  },
};

//override specific links for BNI Chapter Member
const bhpEditLinksBNIMember = {
  ...bhpEditLinks,
  ...{
    addProduct:
      "/landing-page?type=Table&id=335&singleCellEdit=1&addModeOnly=1&prodId=0&backOnSave=1&schoolId=%SCHOOL_ID%",
    editProduct:
      "/landing-page?type=Table&id=341&singleCellEdit=1&prodId=%prodId%&backOnSave=1&schoolId=%SCHOOL_ID%",
  },
};

export {
  deleteElementFromMapsDB,
  loginUserReceiptDB,
  getReceiptsDB,
  saveReceiptDB,
  bhpEditLinks,
  bhpEditLinksBNIChapter,
  bhpEditLinksBNIMember,
  userSessionKey,
  userSessionTimeKey,
  getAllMarketBlocksDB,
  contactSupport,
  whatsappMessage,
  getBlogsListDB,
  getBlogDB,
  getBlogHtmlFile,
  saveUserInteractionDB,
  getPremptMktShopNameDB,
  assignBhpStockImagesDB,
  savePloygonLineClusterDB,
  tableBookingDB,
  getCompanyDomainFromParamsDB,
  getShopMapMarkerAssocDB,
  updateShopAssignmentDB,
  saveMapsAdditionalStyleDB,
  subscribeToOfferDB,
  getTableBookingDB,
  updateTableBookingDB,
  getCustomerDB,
  getMarketFloorsDB,
  getMarketFloorLayoutDB,
  saveMarketFloorLayoutDB,
  getLoyaltyProgramRulesDB,
  getLoyaltySnapshotPerUserOrShopDB,
  getShopChecklistDataDB,
  saveShopChecklistDataDB,
  setLoyaltyProgramRulesDB,
  registerNewLoyaltyCustomerDB,
  addRedeemLoyaltyPointsDB,
  getLoyaltyDetailsPerUserAndShopDB,
  getCustomerShopLoyaltySnapshot,
  getBhpMarketsDB,
  getCouponsDB,
  getAllCustomerQueriesDB,
  replyToCustomerQueryDB,
  getOrderStatusDB,
  vypzeeSupportNum,
  getBhpHotKeysDB,
  updateOrderStatusDB,
  getItemizedOrderDetailsDB,
  getOrderDetailsDB,
  getQueryDetailsDB,
  knownDomains,
  vCardGetDB,
  validateCouponDB,
  redeemCouponDB,
  getTinyUrlDetailsDB,
  domain,
  googleSignIn,
  getFavShopMarketListDB,
  writeFavShopMarketListDB,
  generateResumeDB,
  getResumeCssDB,
  getResumeHtmlDB,
  getResumeDataDB,
  getBhpCompanyDetailsDB,
  getBhpTeamDetailsDB,
  getBhpProductDetailsDB,
  getBhpPromoProductDetailsDB,
  getBhpTestimonialsDetailsDB,
  saveBhpTestimonialsDetailsDB,
  queryBhpTestimonialsDetailsDB,
  registerShoppingListDB,
  createEditShoppingListDB,
  getShoppingListDB,
  saveBhpCustomerQueryDB,
  checkAndSaveDomainName,
  rupeeSymbol,
  updateSchoolDB,
  updateUserDataDB,
  addCheckUserDB,
  getSsiEcaScoreDB,
  getSsiListDB,
  getEcaActivitiesDB,
  addSsiEcaScoreDB,
  getSsiCountDB,
  getTermListDB,
  careerPredictorApi,
  saveTableDB,
  generateReportCards,
  deleteTableDB,
  deleteTableEntryDB,
  saveTableDataBackend,
  getTableDataDB,
  getDashboardDB,
  getTableDB,
  saveTableColSizeDB,
  getTablesListDB,
  saveFormDB,
  saveBlob,
  getFormDB,
  getFormsListDB,
  getSetResetPasswordDB,
  getDynamicColValuesDB,
  getDynamicColValuesDBForForm,
  saveFormInstance,
  getFormInstanceList,
  getFormInstanceDetails,
  saveEventDB,
  deleteEventDB,
  updateAccolade,
  getTimeTableClassList,
  reviewCertificatesPath,
  IDIGIZEN_BACKEND_URL,
  BACKEND_SERVER_LOCAL,
  IDIGIZEN_FRONTEND_URL,
  API_SECURE_KEY,
  getLandingPageIconImg,
  deleteAnecdote,
  initTimeTable,
  saveTimeTable,
  getReportCardReview,
  getTimeTable,
  getEventParticipantsDB,
  updateEventParticipantsDB,
  DOMAIN_PATH,
  userInfoRouter,
  generateCertificatesCommand,
  updateEventStatusDB,
  getLandingPageSpecs,
  getStudentAttendanceListSessionClassSection,
  subClassSecSessExamBundleRouter,
  subClassSecSessExamMarksSaveRouter,
  getBehaviorTraitsRouter,
  subClassSecSessTraitsBundleSaveRouter,
  deleteReportCardComments,
  studentIProfileMarksSave,
  logoutRoute,
  getCertTemplateThumbnailFolder,
  getFileThumbnailDB,
  getEventDetailsDB,
  getCertFontNames,
  getCertFontFolder,
  sqlPullStudentAccolades,
  sqlPullStudentList,
  getBehaviorTraitsPerStudentRouter,
  addParticipantToDB,
  subClassSecSessStudentTraitsBundleSaveRouter,
  getStudentListSessionClassSection,
  getSessPerStudentReport,
  reportVerticals,
  saveStudentAttendance,
  getStudentReportCardComments,
  getEventInputDB,
  getReportCardComments,
  getCertTemplateNames,
  saveReportCardComments,
  getAnecdoteList,
  getEventListDB,
  getStudentAnecdoteList,
  saveAnecdote,
  getReportCardThumbnail,
  getReportCardStudentListClassSection,
  generateTimeTable,
  savePTMLog,
  saveHomeWork,
  getHomeWorkList,
  getStudentPTMLogs,
  deleteEventParticiapntDB,
  updateUserInfo,
  uploadFiles,
  getCompletion,
  generateOtpDB,
  regenerateOtpDB,
  verifyOtpDB,
  recordDiscountDB,
  getDiscountDB,
  getTableMobStructDB,
  saveTableMobStructDB,
  getEventDetailsForBusinessPage,
  registerZPreneurStudent,
  zPreneurCourseDetails,
  getCountryStateCityNGstData,
  placeZPreneurOrder,
  certiDownloadURL,
  validateCoupon,
  saveMerchantPromoDb,
  downloadCouponForPromotion,
  getSearchResults,
  getPreEmptiveSearchResults,
  encryptionKey,
  recordToSResponse,
  getShopStockPhotosDB,
  saveShopStockPhotosDB,
  getUnapprovedLoyaltyEntryDB,
  saveStatusUnapprovedLoyaltyEntryDB,
  downloadRawQrForShop,
  sendQueryForProductInfo,
  getMetaTags,
  sendMessageToCustomer,
  associateShopWithUser,
  userUIDKey,
};

export { isKnownDomain, getPageType };
export type { UserInteractionRecordType };
